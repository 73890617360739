const aboutcard2info = [
  {
    id: 1,
    image:'/images/about_info/Image20241103173450.png',
    title: 'What_we_do',
    desc: 'What_we_do_desc',
  },
  {
    id: 2,
    image:'/images/about_info/Image20241103173501.png',
    title: 'Who_we_are',
    desc: 'Who_we_are_desc',
  },
  {
    id: 3,
    image:'/images/about_info/Image20241103173455.png',
    title: 'How_it_work',
    desc: 'How_it_work_desc',
  },
]

export default aboutcard2info
